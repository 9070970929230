
	import { Component, Prop, Vue } from "vue-property-decorator";
	import RecordListItem from "./RecordListItem.vue";

	import type { BaseGiveawayRecordItem } from "@/models/Reception";

	@Component({ components: { RecordListItem } })
	export default class RecordList<T extends BaseGiveawayRecordItem> extends Vue {
		@Prop() public data!: T[];
	}
