
	import { Component, Inject, Vue } from "vue-property-decorator";

	declare global {
		interface Window {
			nfcCallback: (v: string) => void;
		}
	}

	const NOT_SUPPORTED = -100;
	const PENDING = 1;

	@Component
	export default class Page extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		protected state: number = PENDING;

		mounted(): void {
			if(typeof jsInterface.nfcStart != "function") {
				this.state == NOT_SUPPORTED;
			} else {
				window.nfcCallback = (v: string) => this.callback(v);
				this.start();
			}
		}

		beforeDestroy(): void {
			jsInterface.nfcStop();
		}

		protected callback(id: string): void {
			this.$router.push("/reception/card/" + id);
		}

		protected async start(): Promise<void> {
			this.state = await jsInterface.nfcStart();
		}
	}
