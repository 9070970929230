
	import { Component, Inject, Vue } from "vue-property-decorator";

	import Outlet from "./components/Outlet.vue";

	import type { ReceptionIndexModel } from "@/models/Reception";

	@Component({ components: { Outlet } })
	export default class Page extends Vue {
		@Inject() public readonly confirm!: ConfirmMethod;

		protected data: ReceptionIndexModel | null = null;

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = await post<ReceptionIndexModel>("/api/Reception");
		}
	}
