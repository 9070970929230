
	import { Component, Prop, Vue } from "vue-property-decorator";
	import { mark } from "@common/string";

	@Component
	export default class Eye extends Vue {

		@Prop() public text!: string;
		@Prop() public symbol!: string;
		@Prop() public head!: number;
		@Prop() public tail!: number;

		protected show: boolean = false;

		protected get display(): string {
			if(this.show) return this.text;
			return mark(this.text, this.symbol, this.head, this.tail);
		}
	}
