
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";
	import Scanner from "@/components/Scanner.vue";
	import TicketModal from "../info/scan/TicketModal.vue";

	@Component({ components: { Scanner, TicketModal } })
	export default class Page extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		@Prop() public id!: string;

		protected async scan(code: string): Promise<void> {
			const match = code.match(/(eg|pg)\/([0-9a-f]{32})$/);
			if(!match) {
				await this.alert("票券格式不正確");
			} else {
				this.$router.push(`/reception/ticket/${this.id}/${match[1]}/${match[2]}`);
			}
		}

		protected async input(): Promise<void> {
			const code = await this.$refs.add.show();
			if(code != null) {
				const url = await post<string>('/api/Info/QueryTicket/', { Id: code });
				if(url != null) {
					await this.scan(url);
				} else {
					await this.alert("券號無效");
				}
			}
		}
	}
