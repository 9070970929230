
	import { Component, Inject, Prop, Vue } from "vue-property-decorator";

	import Outlet from "./components/Outlet.vue";
	import type { DistributerClaimResult, DistributerRequestResult } from "@/models/Reception";


	const ONE_MINUTE = 60;
	const INTERVAL = 500;
	const ONE_SECOND = 1000;

	@Component({ components: { Outlet } })
	export default class Page extends Vue {
		@Inject() public readonly alert!: AlertMethod;
		@Inject() public readonly confirm!: ConfirmMethod;

		@Prop() public id!: string;

		protected data: DistributerRequestResult | null = null;

		protected phone: string | null = null;
		protected remain: number = ONE_MINUTE;

		get title(): string {
			if(!this.data) return "";
			return this.data.Promotion.CodeName == "eventgiveaway" ? "發送次數" : "發送集點";
		}

		mounted(): void {
			this.load();
		}

		private async load() {
			try {
				this.data = await post<DistributerRequestResult>("/api/Reception/GetRecord", { Id: this.id });
				setTimeout(() => this.countdown(), INTERVAL);
			} catch(e) {
				if(e instanceof Error) await this.alert(e.message);
				this.$router.back();
			}
		}

		protected countdown(): void {
			const now = new Date().getTime();
			this.remain = ONE_MINUTE - Math.ceil((now - this.data!.CreateTime.getTime()) / ONE_SECOND);
			if(this.remain <= 0) this.$router.back();
			else setTimeout(() => this.countdown(), INTERVAL);
		}

		protected async send(): Promise<void> {
			if(!this.data) return;
			const result = await post<DistributerClaimResult>("/api/Reception/Send", { Id: this.id, Name: this.phone! });
			const next = await this.confirm(
				`
					<div class="row">
						<div class="col">會員：</div>
						<div class="col text-right">
							${result.UserSerial}<br>${this.phone}
						</div>
					</div>
					<hr>
					<div class="p-2">${this.data.Promotion.Name}</div>
					<div class="row">
						<div class="col">${this.data.Item.Name}：</div>
						<div class="col text-right">${result.Count} ${this.data.Item.Unit}</div>
					</div>
					<div class="row">
						<div class="col">發送門市：</div>
						<div class="col text-right">${result.Outlet}</div>
					</div>
				`,
				result.ReceiveTime.minute() + "<br>兌換成功",
				"success",
				"繼續發送", "關閉"
			);
			if(next) this.$router.back();
			else this.$router.push("/reception");
		}

	}
