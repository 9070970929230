
	import { Component, Prop, Vue } from "vue-property-decorator";

	import type { ReceptionIndexItem } from "@/models/Reception";

	@Component
	export default class Outlet extends Vue {
		@Prop() public data!: ReceptionIndexItem;

		public go(codename: string): void {
			this.$router.push(`/reception/prepareSend/${this.data.Id}/${codename}`);
		}
	}
