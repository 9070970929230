
	import { Component, Prop, Vue } from "vue-property-decorator";

	import type { BaseGiveawayTicketModel, BaseGiveawayTicketItem } from "@/models/Reception";

	@Component
	export default class InfoList extends Vue {
		@Prop() public data!: BaseGiveawayTicketModel;

		protected startedOpen: boolean = false;
		protected notStartedOpen: boolean = false;

		protected get started(): BaseGiveawayTicketItem[] {
			return this.data.Tickets.filter(t => t.Started);
		}

		protected get notStarted(): BaseGiveawayTicketItem[] {
			return this.data.Tickets.filter(t => !t.Started);
		}

		protected sum(records: BaseGiveawayTicketItem[]): number {
			return records.reduce((a, b) => a + b.Amount, 0);
		}
	}
