
	import { Component, Prop, Vue } from "vue-property-decorator";

	import type { InfoIndexModel } from "@/models/Reception";

	@Component
	export default class Page extends Vue {
		@Prop() public id!: string;

		protected data: InfoIndexModel[] | null = null;

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = await post<InfoIndexModel[]>("/api/Reception/Lookup", { Id: this.id });
		}

		protected back(): void {
			this.$router.back();
		}

		protected goto(id: string): void {
			this.$router.push(`/reception/outlet/${this.id}/${id}`);
		}
	}
